import {postJson} from 'web/helpers/api_helpers';

export type Plans = 'monthly' | 'yearly';

export interface StripeOptions {
  plan: Plans;
  includeTrial: boolean;
  returnTo?: string;
}

export async function getCheckoutSessionUrl(stripeOptions?: StripeOptions): Promise<string> {
  return postJson(
    '/api/account/membership/create-checkout-session',
    stripeOptions ? {body: {...stripeOptions}} : {},
  );
}

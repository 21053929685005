import classNames from 'classnames';
import React, {ReactElement, useState} from 'react';
import {connect} from 'react-redux';
import Cents from 'goodeggs-money';

import assetPath from 'web/helpers/asset_path';
import {DisplayTotal, FulfillmentOffer} from 'web/basket/basket_page';
import useClientSettings from 'web/hooks/useClientSettings';

import {InfoModal} from '../info_modal';
import SavingBanner from '../saving_banner';

const DELIVERY_FEE_KEY = 'deliveryFee';
const GOOD_JOBS_FEE_KEY = 'goodJobsFee';

type TotalRowProps = Omit<DisplayTotal, 'amount'> & {
  className: string;
  selector: string;
  isMembershipOrder?: boolean;
  feeKey: string;
  discountValues?: DiscountValuesProps;
};

interface DiscountValuesProps {
  discountKey: string;
  discountValue: number;
}

const TotalRow = ({
  className,
  formattedAmount,
  info,
  label,
  secondaryLabel,
  selector,
  alert,
  isMembershipOrder,
  feeKey,
  discountValues,
}: TotalRowProps): ReactElement => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const isDeliveryFeeRow = selector.indexOf(DELIVERY_FEE_KEY) > 0;

  const {discountKey, discountValue} = discountValues ?? {};

  const onClickInfo = (): void => {
    setIsInfoModalOpen(true);
  };

  const onDismissInfo = (): void => {
    setIsInfoModalOpen(false);
  };

  return (
    <div className={classNames('totals__total-row', className)}>
      {Boolean(secondaryLabel) && (
        <span className="totals__total-secondary-label">{secondaryLabel}</span>
      )}

      <span className="totals__total-label">
        {Boolean(alert) && <span className="totals__total-alert">{alert}</span>}
        {label}
        {info && !isDeliveryFeeRow && (
          <div className="totals__total-info">
            <span className="totals__total-info-link" onClick={() => onClickInfo()}>
              <img src={`${assetPath('/img/web/questionmark_web.png')}?auto=format`} />
            </span>
            {isInfoModalOpen && <InfoModal info={info} onClose={onDismissInfo} />}
          </div>
        )}
      </span>

      {isDeliveryFeeRow ? (
        <div>
          <span className="totals__delivery-fee-amount">
            {discountKey ? `$${discountValue}` : formattedAmount}
          </span>
        </div>
      ) : (
        <div />
      )}

      <span
        className={classNames('totals__total-formatted-amount', selector, {
          'totals__delivery-fee': isDeliveryFeeRow,
          'totals__membership-fee': [GOOD_JOBS_FEE_KEY].includes(feeKey) && isMembershipOrder,
        })}
      >
        {isDeliveryFeeRow ? 'FREE' : formattedAmount}
      </span>
    </div>
  );
};

export interface DisplayTotalsProps {
  displayTotals?: DisplayTotal[];
  showDeliveryFee: boolean;
  isMembershipOrder?: boolean;
  totalSavings?: number;
  selectedFulfillmentOffer?: FulfillmentOffer;
}

const DisplayTotals = ({
  displayTotals,
  showDeliveryFee,
  isMembershipOrder,
  totalSavings,
}: DisplayTotalsProps): ReactElement => {
  const clientSettings = useClientSettings();
  const displayTotalsToShow = showDeliveryFee
    ? displayTotals
    : displayTotals?.filter(({key}) => key !== DELIVERY_FEE_KEY);

  const displayTotalsToShowFiltered = displayTotalsToShow
    ? displayTotalsToShow.map((item: DisplayTotal) => {
        if (item.key === DELIVERY_FEE_KEY) {
          return {
            ...item,
            discountValues: {
              discountKey: DELIVERY_FEE_KEY,
              discountValue: new Cents(clientSettings.strikedValue).toDollars(),
            },
          };
        }
        return item;
      })
    : [];

  return (
    <div className="totals">
      {displayTotalsToShowFiltered?.map((displayTotal) => (
        <TotalRow
          key={displayTotal.key}
          className={`totals__total-row__${displayTotal.key}`}
          selector={`js-totals__${displayTotal.key}`}
          label={displayTotal.label}
          formattedAmount={displayTotal.formattedAmount}
          secondaryLabel={displayTotal.secondaryLabel}
          info={displayTotal.info}
          alert={displayTotal.alert}
          isMembershipOrder={isMembershipOrder}
          feeKey={displayTotal.key}
          discountValues={displayTotal.discountValues}
        />
      ))}
      {totalSavings != null &&
        totalSavings > 0 &&
        clientSettings.strikethroughPricing.enableWeb && (
          <SavingBanner totalSavings={totalSavings} />
        )}
    </div>
  );
};

interface State {
  selectedFulfillmentOffer: FulfillmentOffer;
}

export function mapStateToProps(state: State): {selectedFulfillmentOffer: FulfillmentOffer} {
  const {selectedFulfillmentOffer} = state;
  return {selectedFulfillmentOffer};
}

export default connect(mapStateToProps)(DisplayTotals);
